import axios from 'axios';

import type { StileJobJson } from '../../../../types/data';

const STILE_JOB_BY_ID_API_URL = `https://stileapp.com/api/au/v3/jobRoles/getMarketingJobData/`;

export async function fetchStileJobById(jobId: string) {
  const { data } = await axios.get<StileJobJson>(`${STILE_JOB_BY_ID_API_URL}${jobId}`);
  return data;
}
