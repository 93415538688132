import { getJobIdFromPath } from './getJobIdFromPath';

export function getApplySEO(
  path: string,
  jobMeta: { [key: string]: Array<string> }
): { slug: string; opengraphTitle?: string; opengraphDescription?: string } {
  const jobId = getJobIdFromPath(path);

  // Make sure we've found a job id that we do actually have data for
  if (jobId && jobId in jobMeta) {
    return {
      slug: path,
      opengraphTitle: jobMeta[jobId][0],
      opengraphDescription: jobMeta[jobId][1],
    };
  } else {
    // Fallback SEO if jobId is not provided or fails to parse
    return { slug: path };
  }
}
