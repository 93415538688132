import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { useSearchParam } from 'react-use';

import { JobDescriptionBlock } from 'stile-shared/src/components/2020/blocks/JobDescriptionBlock';
import { PageContextType } from 'stile-shared/src/types/data';
import { getApplySEO } from 'stile-shared/src/utils/getApplySEO';
import { getJobIdFromPath } from 'stile-shared/src/utils/getJobIdFromPath';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

export function Head(props: HeadProps<object, PageContextType>) {
  const seoData = getApplySEO(props.location.pathname, props.pageContext.jobMeta);

  return (
    <SEO
      slug={seoData.slug}
      opengraphTitle={seoData?.opengraphTitle}
      opengraphDescription={seoData?.opengraphDescription}
    />
  );
}

function ApplyPage(props: PageProps) {
  // First try to get jobId from the search params
  let jobId = useSearchParam('job') ?? '';

  // Try and get it from the path instead if it wasn't in params
  if (!jobId) {
    jobId = getJobIdFromPath(props.location.pathname) ?? '';
  }

  return (
    <PageLayout {...props}>
      <JobDescriptionBlock jobId={jobId} />
    </PageLayout>
  );
}

export default ApplyPage;
