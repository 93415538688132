import * as React from 'react';

import {
  FACEBOOK_DOMAIN_VERIFICATION,
  GOOGLE_SITE_VERIFICATION,
  LOCALE_URLS,
  SITE_TITLE,
  SITE_URL,
} from '../constants';

import { getRegionFromLocale } from '../utils/getRegionFromLocale';
import { replacePrefix } from '../utils/replacePrefix';

import applyOpengraphImage from '../../assets/images/opengraph/apply.png';
import defaultOpengraphImage from '../../assets/images/opengraph/default.png';

type opengraphImageOptions = 'default' | 'apply';

export type BaseSEOProps = {
  title: string;
  description: string;
  slug: string;
  locale: string;
  uniqueToLocale?: boolean;
  opengraphTitle?: string;
  opengraphDescription?: string;
  opengraphImageSelection?: opengraphImageOptions;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
};

const opengraphImages = {
  default: defaultOpengraphImage,
  apply: applyOpengraphImage,
};

export function BaseSEO({
  title,
  description,
  slug,
  locale,
  uniqueToLocale = false,
  opengraphTitle = title,
  opengraphDescription = description,
  opengraphImageSelection = 'default',
  twitterTitle = title,
  twitterDescription = description,
  twitterImage = opengraphImageSelection,
  children,
}: React.PropsWithChildren<BaseSEOProps>) {
  const augmentedTitle = RegExp(SITE_TITLE).test(title) ? title : `${title} | ${SITE_TITLE}`;
  const canonicalUrl = new URL(slug, SITE_URL).href;

  const opengraphImage = opengraphImages[opengraphImageSelection];

  if (twitterImage == opengraphImageSelection) {
    // if we haven't overridden the twitter image with a different image to the opengraph one, update it to the actual imported image too
    twitterImage = opengraphImage;
  }

  return (
    <>
      {/* Lighthouse recommends pre-connecting to google tag manager for speedier resolve when script loaded in body */}
      <link rel="preconnect" key="preconnect-google-gtag" href="https://www.googletagmanager.com" />
      <link
        rel="dns-prefetch"
        key="dns-prefetch-google-gtag"
        href="https://www.googletagmanager.com"
      />

      <title id="seo-title">{augmentedTitle}</title>
      <meta
        id="seo-facebook-domain-verification"
        name="facebook-domain-verification"
        content={FACEBOOK_DOMAIN_VERIFICATION}
      />
      <meta
        id="seo-google-site-verification"
        name="google-site-verification"
        content={GOOGLE_SITE_VERIFICATION}
      />
      <meta id="seo-description" name="description" content={description} />
      <meta id="seo-og:title" property="og:title" content={opengraphTitle} />
      <meta id="seo-og:description" property="og:description" content={opengraphDescription} />
      <meta id="seo-og:image" property="og:image" content={opengraphImage} />
      <meta id="seo-twitter:card" property="twitter:card" content="summary" />
      <meta id="seo-twitter:title" property="twitter:title" content={twitterTitle} />
      <meta
        id="seo-twitter:description"
        property="twitter:description"
        content={twitterDescription}
      />
      <meta id="seo-twitter:image" property="twitter:image" content={twitterImage} />

      <link id="seo-icon" rel="icon" href="/favicon.ico" />

      <link id="seo-canonical" rel="canonical" href={canonicalUrl} />

      {/* Create hreflang links if we're not on testing domain, and page is not unique to site */}
      {!process.env.GATSBY_TESTING_PREFIX &&
        !uniqueToLocale &&
        getHrefLinks(LOCALE_URLS, slug, locale)}

      {children}
    </>
  );
}

/**
 * Creates alternate page links for all locales, including default lang, and self-referencing!
 */
function getHrefLinks(locales: typeof LOCALE_URLS, slug: string, currentLocale: string) {
  return Object.entries(locales).map(([altLocale, altBaseUrl]) => {
    // strip locale prefix from slug since target locale is already present in alternate baseUrl
    const altHref = altBaseUrl + replacePrefix(slug, getRegionFromLocale(currentLocale), '/');

    return (
      <link
        key={altLocale}
        id={`seo-alt-${altLocale}`}
        rel="alternate"
        hrefLang={altLocale}
        href={altHref}
      />
    );
  });
}
